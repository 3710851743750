import React, { useState } from "react";
import { jackColors } from "../assets/colors";
import { JackIcons } from "../assets/jackIcons/parent";
import { removeDuplicates } from "./tools";

export const useIdsTable = (data, defaultIds) => {
  const getAllIds = data.map(({ id }) => id);

  const [selectedIds, setSelectedIds] = useState(defaultIds || []);

  const isAllChecked =
    getAllIds.length && getAllIds.every((id) => selectedIds.includes(id));

  const handleClickAll = () => {
    if (!getAllIds.length) return;
    if (isAllChecked)
      return setSelectedIds((prev) =>
        prev.filter((id) => !getAllIds.includes(id))
      );
    setSelectedIds((prev) => removeDuplicates([...prev, ...getAllIds]));
  };

  const handleAddId = (id) => setSelectedIds((prev) => [...prev, id]);

  const handleRemoveId = (id) =>
    setSelectedIds((prev) => prev.filter((item) => item !== id));

  const handleRemoveAllId = () => {
    setSelectedIds([]);
  };
  const handleClickItem = (id, isActive) => {
    if (isActive) return handleRemoveId(id);
    handleAddId(id);
  };
  const selectedAmount = selectedIds.length;
  return {
    handleClickAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
    selectedAmount,
  };
};

export const CheckBox = ({
  isActive,
  onClick: onClickProps,
  style,
  type = "square",
  fillCustom,
  nameCustom,
  alignItems,
  disableSelected = false,
  disabled,
}) => {
  //type = 'square' | 'circle'
  const isDisabled = disableSelected || disabled;

  const nameDecider = () => {
    if (nameCustom) return nameCustom;

    const isSquare = type == "square";
    const squareOrCircle = isSquare ? "Square" : "Circle";
    const filledOrEmpty = isActive || disableSelected ? "Filled" : "Empty";
    const disabled = isDisabled ? "Disabled" : "";

    return `Checkbox${squareOrCircle}${filledOrEmpty}`;
  };

  const fillDecider = () => {
    if (isDisabled) return jackColors.greyE6;
    if (fillCustom) return fillCustom;
    if (isActive) return jackColors.black34;
    return jackColors.greyE6;
  };

  const name = nameDecider();
  const fill = fillDecider();

  const onClick = (e) => {
    if (isDisabled) return;
    onClickProps && onClickProps(e);
  };

  return (
    <JackIcons
      name={name}
      className="hover"
      fill={fill}
      style={{
        width: 20,
        height: 20,
        ...style,
        cursor: isDisabled ? "default" : "pointer",
      }}
      onClick={onClick}
      alignItems={alignItems}
    />
  );
};

export const deepUseIdsTable = (data) => {
  const getAllIds = data?.map((array, index) => index);

  const [selectedIds, setSelectedIds] = useState([]);

  const isAllChecked =
    getAllIds.length && getAllIds.length == selectedIds.length;

  const handleClickAll = () => {
    if (isAllChecked) return setSelectedIds([]);
    setSelectedIds(getAllIds);
  };

  const handleUncheckedAll = () => setSelectedIds([]);

  const handleAddId = (id) => setSelectedIds((prev) => [...prev, id]);

  const handleRemoveId = (id) =>
    setSelectedIds((prev) => prev.filter((item) => item !== id));

  const handleRemoveAllId = () => {
    setSelectedIds([]);
  };
  const handleClickItem = (id, isActive) => {
    if (isActive) return handleRemoveId(id);
    handleAddId(id);
  };
  const selectedAmount = selectedIds.length;
  return {
    handleClickAll,
    handleUncheckedAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
    selectedAmount,
  };
};
