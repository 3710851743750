import { times } from "lodash";
import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { colors } from "../../assets/colors";
import {
  StatusAccountIcon,
  StatusAccountTag,
  StatusActivities,
  StatusLabel,
} from "../../components/Status";
import {
  GothamMedium,
  GothamRegular,
  OldGothamRegular,
  TypeFezNeueRegular,
} from "../../components/Text";
import { windowDimension } from "../../components/tools";
import { TransitionDiv } from "../../components/Transition";

const TransactionShimmer = () =>
  times(10, (index) => (
    <AccountDetailsTransDiv key={index}>
      <div className="d-flex w-100  justify-content-between">
        <div className="d-flex align-items-center">
          <Skeleton width={28} height={28} style={{ marginRight: 12 }} />
          <div>
            <Skeleton width={150} height={15} />
            <div>
              <Skeleton width={100} height={15} />
            </div>
          </div>
        </div>
        <Skeleton width={100} height={15} />
      </div>
    </AccountDetailsTransDiv>
  ));

const leftBottomPhoneFormatter = (leftBottom) => {
  const splittedLeftBottom = (leftBottom || "").includes("• ")
    ? leftBottom.split("• ")
    : [];
  const referenceNumber = splittedLeftBottom[1] || "";
  const date = splittedLeftBottom[2] || "";

  const cleanLeftBottom =
    (leftBottom || "")
      .replace(referenceNumber, "")
      .replace(date, "")
      .split("•") || [];
  const modifiedLeftBottom = cleanLeftBottom.filter(
    (string) => string.length > 1
  );

  return { referenceNumber, date, modifiedLeftBottom };
};

export const TransactionActivitiesPhone = ({ data, onClick }) =>
  data.map((item, index) => {
    const {
      category,
      type,
      leftTop,
      leftMiddle,
      leftTag,
      leftStatus,
      leftBottom,
      rightTop,
      rightBottom,
    } = item;
    const widthStatusAccountIcon = 28;

    const { date, referenceNumber, modifiedLeftBottom } =
      leftBottomPhoneFormatter(leftBottom);

    return (
      <div
        key={index}
        className="py-2 px-1 darkhover"
        style={{ borderBottom: "1px solid #eaeaea" }}
        onClick={() => onClick(item)}
      >
        <div className="mb-3">
          {referenceNumber && (
            <div className="d-flex justify-content-between align-items-start">
              <div>
                <GothamMedium className="font12 mb-1">
                  {referenceNumber}
                </GothamMedium>
                <GothamRegular className="font10">{date}</GothamRegular>
              </div>
              <StatusActivities
                state={leftStatus}
                className="m-0 mr-1"
                style={{ fontSize: 12, lineHeight: "16px" }}
              />
            </div>
          )}
        </div>
        <div className="d-flex align-items-center w-100 ">
          <StatusAccountIcon state={category || type} />
          <div style={{ width: `calc(100% - ${widthStatusAccountIcon}px)` }}>
            <div className="d-flex justify-content-between mb-1">
              <GothamMedium
                style={{
                  width: "40%",
                  whiteSpace: "nowrap",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  marginLeft: 12,
                }}
              >
                {leftTop}
              </GothamMedium>
              <div>
                <GothamMedium
                  style={{
                    textAlign: "right",
                  }}
                >
                  {rightTop}
                </GothamMedium>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <GothamRegular
                  className="font10"
                  style={{
                    marginLeft: 12,
                  }}
                >
                  {leftMiddle}
                </GothamRegular>
              </div>
              <div>
                <GothamRegular>{rightBottom}</GothamRegular>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-2 mb-1">
          {modifiedLeftBottom && (
            <>
              <GothamRegular className="font10">
                {modifiedLeftBottom}
              </GothamRegular>
              {leftTag && (
                <StatusAccountTag
                  state={leftTag}
                  style={{ marginLeft: 10 }}
                  fromModal
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  });

export const TransactionActivities = ({
  data,
  onClick,
  loading,
  fromModal,
  notInfiniteScroll,
  batchState,
  singleIcon,
  itemStyle,
  woStateFormatter,
}) => {
  const { isTabOrPhone } = windowDimension();

  if (notInfiniteScroll && loading) return <TransactionShimmer />;

  const handleClick = (item) => onClick && onClick(item);

  const containerProps = {
    style: {
      border: "1px solid #eaeaea",
      borderRadius: 8,
      paddingLeft: 12,
      paddingRight: 12,
      paddingBottom: 16,
      paddingTop: 16,
      marginBottom: 8,
      ...itemStyle,
    },
    className: "darkhover",
  };

  if (isTabOrPhone) {
    return <TransactionActivitiesPhone data={data} onClick={handleClick} />;
  }

  return (
    <>
      {data
        .map((item, index) => {
          const {
            category,
            type,
            leftTop,
            leftMiddle,
            leftTag,
            leftStatus,
            leftBottom,
            rightTop,
            rightBottom,
            state,
          } = item;

          const leftStatusDecider = () => {
            const isOnProgress = batchState == "on_progress";
            const isPending = leftStatus == "pending";

            const isBatchState =
              (isOnProgress && isPending) ||
              batchState === "waiting_for_payment" ||
              batchState === "draft";

            if (isBatchState) return batchState;

            return leftStatus;
          };
          const isTopUp = category === "topup_transaction";
          const isSingleCrossBorder = category === "single_cross_border";
          const isPayroll = category === "payroll";

          const newDesign = isSingleCrossBorder || isPayroll;

          return (
            <TransitionDiv
              counter={index}
              key={index}
              onClick={() => handleClick(item)}
              {...containerProps}
            >
              <div className="d-flex align-items-center">
                <div style={{ marginRight: 12 }}>
                  <StatusAccountIcon
                    state={singleIcon ? "local_disbursement" : category || type}
                  />
                </div>
                <div
                  style={{
                    width: "90%",
                  }}
                >
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "100%" }}
                  >
                    <GothamMedium
                      className="m-0"
                      style={{
                        fontSize: !fromModal ? 14 : 12,
                        lineHeight: !fromModal ? "22px" : "18px",
                        width: "60%",
                        wordWrap: "break-word",
                      }}
                    >
                      {isTopUp
                        ? `${leftTop} - ${item.details.bank_name}`
                        : leftTop}
                    </GothamMedium>
                    <GothamRegular
                      style={{
                        color: colors.grey25,
                        fontSize: !fromModal ? 14 : 12,
                        lineHeight: !fromModal ? "22px" : "18px",
                        textAlign: "right",
                      }}
                      className="m-0"
                    >
                      {rightTop}
                    </GothamRegular>
                  </div>
                  {leftMiddle && (
                    <div style={{ marginTop: 4 }}>
                      <GothamRegular
                        className="m-0"
                        style={{
                          fontSize: 10,
                          lineHeight: "16px",
                        }}
                      >
                        {leftMiddle}
                      </GothamRegular>
                    </div>
                  )}
                  <div
                    className="d-flex justify-content-between"
                    style={{ marginTop: 4 }}
                  >
                    <div className="d-flex align-items-center">
                      {leftStatus && newDesign ? (
                        <StatusLabel status={state} className="mr-1" />
                      ) : (
                        <StatusActivities
                          state={leftStatusDecider()}
                          className="m-0 mr-1"
                          style={{ fontSize: 10, lineHeight: "16px" }}
                          woStateFormatter={woStateFormatter}
                        />
                      )}
                      {newDesign ? (
                        <TypeFezNeueRegular
                          style={{
                            fontSize: 12,
                            lineHeight: "16px",
                            color: colors.grey6c,
                          }}
                        >
                          {leftBottom && <span className="mx-1">•</span>}{" "}
                          {leftBottom}
                        </TypeFezNeueRegular>
                      ) : (
                        <OldGothamRegular
                          className="m-0"
                          style={{ fontSize: 10, lineHeight: "16px" }}
                        >
                          {leftBottom}
                        </OldGothamRegular>
                      )}
                      {leftTag && (
                        <StatusAccountTag
                          state={leftTag}
                          style={{ marginLeft: 10 }}
                          fromModal
                        />
                      )}
                    </div>
                    <OldGothamRegular
                      style={{
                        fontSize: !fromModal ? 12 : 10,
                        color: colors.grey72,
                        marginBottom: 0,
                        textAlign: "right",
                      }}
                    >
                      {rightBottom}
                    </OldGothamRegular>
                  </div>
                </div>
              </div>
            </TransitionDiv>
          );
        })
        .filter((item) => item)}
      {loading && <TransactionShimmer />}
    </>
  );
};

const AccountDetailsTransDiv = styled.div`
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 18px;
  flex-wrap: nowrap;
  margin-bottom: 16px;
`;
