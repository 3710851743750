import React, { useEffect, useRef, useState } from "react";
import { colors } from "../assets/colors";
import { unformatCurrency, unformatCurrencyDecimal } from "../components/tools";
import { GetFlag, useRegisterField } from "./inputs";
import { LoadingWrapper } from "./LoadingWrapper";
import { GothamMedium, GothamRegular, TextInlineMedium } from "./Text";
import { formatCurrency, formatCurrencyNoDecimal, useDebounce } from "./tools";

const CustomInput = ({
  style,
  onChange,
  value,
  prefix = "",
  bluePrefix,
  autoFocus = true,
  alignLeft,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!autoFocus) return;
    ref.current.focus();
  }, []);

  const [width, setWidth] = useState(0);
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        style={{
          position: "absolute",
          bottom: 2,
        }}
        ref={(e) => setWidth(e?.clientWidth)}
      >
        <TextInlineMedium
          style={{
            fontSize: 24,
            color: alignLeft
              ? colors.grey90
              : bluePrefix
              ? colors.teal
              : colors.grey33,
            ...style,
          }}
        >
          {prefix}
        </TextInlineMedium>
      </div>
      <input
        ref={ref}
        value={value}
        onChange={onChange}
        style={{
          width: `100%`,
          borderWidth: 0,
          outline: "none",
          textAlign: alignLeft ? "left" : "right",
          borderBottomWidth: 1,
          borderColor: colors.greyea,
          fontSize: 24,
          fontFamily: "GothamMedium",
          caretColor: colors.pink,
          color: colors.grey33,
          paddingLeft: alignLeft ? width + 8 : 0,
          ...style,
        }}
      />
    </div>
  );
};

export const useAccountCalculator = ({
  useFormObj,
  name,
  isDecimal,
  defaultAmount,
  onChange,
  isCalculator = true,
  timeOut = 800,
}) => {
  const formatter = isDecimal ? formatCurrency : formatCurrencyNoDecimal;
  const unformat = isDecimal ? unformatCurrencyDecimal : unformatCurrency;

  const { setValue, currValue } = useRegisterField(useFormObj, name);

  const [text, setText] = useState(formatter(defaultAmount));

  const safeText = text || 0;

  const debouncedText = useDebounce(text, timeOut);

  useEffect(() => {
    if (isCalculator) {
      const canPass = currValue == 0;
      if (!currValue && !canPass) return;
      setText(formatter(currValue));
      return;
    }

    const canPass = currValue == 0;
    if (!currValue) return setText("");
    if (!canPass) return;
    setText(formatter(currValue));
  }, [currValue]);

  useEffect(() => {
    if (isCalculator) {
      setText(formatter(safeText));
      setValue(name, unformat(safeText));
      return;
    }
    if (String(text) == "0" || text == "") {
      setText("");
      setValue(name, "");
      return;
    }
    setText(formatter(safeText));
    setValue(name, unformat(safeText));
  }, [debouncedText]);

  const handleChange = (e) => {
    if (!e) return;
    onChange && onChange();
    const dirtyValue = e?.target?.value;
    const value = unformat(dirtyValue);

    // const numberLimit = 11;

    // const stopper = numberLimit < value.length;
    // if (stopper) return;

    setText(dirtyValue);
  };

  return { handleChange, text };
};

export const AccountCalculatorInput = ({
  useFormObj,
  name,
  label,
  country,
  defaultAmount = 0,
  isDecimal = false,
  isVertical,
  prefix,
  bluePrefix,
  onChange,
  loading,
  propsBalance,
  autoFocus,
  isCalculator,
  alignLeft,
  timeOut = 800,
}) => {
  const { currency = "IDR", iso, balanceDetails } = country || {};

  const { handleChange, text } = useAccountCalculator({
    useFormObj,
    defaultAmount,
    isDecimal,
    name,
    onChange,
    isCalculator,
    timeOut,
  });

  const customInputProps = {
    onChange: handleChange,
    value: text,
    prefix: loading ? "" : prefix || currency,
    bluePrefix,
    autoFocus,
    alignLeft,
  };

  if (isVertical)
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: 16 }}>
          <LoadingWrapper className="justify-content-center" loading={loading}>
            <GothamRegular
              className="m-0"
              style={{ fontSize: 12, color: colors.grey33 }}
            >
              <GetFlag
                iso={iso}
                style={{ marginRight: 8, filter: "brightness(95%)" }}
              />
              {label}
            </GothamRegular>
          </LoadingWrapper>
        </div>
        <CustomInput
          {...customInputProps}
          style={{ fontSize: 20, color: "red" }}
        />
        {Boolean(balanceDetails) && (
          <LoadingWrapper
            isSmall
            className="justify-content-center"
            loading={loading}
          >
            <GothamRegular
              style={{
                fontSize: 10,
                textAlign: "center",
                marginTop: 4,
                marginBottom: 0,
              }}
            >
              {balanceDetails}
            </GothamRegular>
          </LoadingWrapper>
        )}
      </div>
    );

  return (
    <div>
      <GothamMedium className="m-0">{label}</GothamMedium>
      <div className="d-flex mt-1">
        {iso && (
          <GetFlag
            iso={iso}
            style={{ marginRight: 24, filter: "brightness(95%)" }}
          />
        )}
        <CustomInput {...customInputProps} />
      </div>
      {Boolean(propsBalance) && (
        <LoadingWrapper
          isSmall
          className="justify-content-center"
          loading={loading}
        >
          <GothamRegular
            style={{
              fontSize: 10,
              textAlign: "right",
              marginTop: 4,
              marginBottom: 0,
              color: colors.grey72,
            }}
          >
            {propsBalance}
          </GothamRegular>
        </LoadingWrapper>
      )}
    </div>
  );
};
