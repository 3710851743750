import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { colors } from "../assets/colors";
import arrowIcon from "../assets/images/arrow.svg";
import WarningIcon from "../assets/images/grey-warning-icon.svg";
import { AccountCalculatorInput } from "../components/AccountInputs";
import { CustomButton } from "../components/Buttons";
import { LeftRightList } from "../components/Lists";
import { SmallScreenWrapper } from "../components/ScreenWrappers";
import { GothamMedium, GothamRegular } from "../components/Text";
import { formatCurrency, useDebounce } from "./tools";
import divideIcon from "../assets/images/divide-icon.svg";
import { useRegisterField } from "./inputs";

export const useCalculatorDetails = ({
  rate,
  source = 0,
  destination,
  loadingRate = false,
}) => {
  const { query } = useRouter();
  const { iso, iso_sender = "IDR" } = query || {};
  const isConvert = iso_sender !== "IDR";

  const array = [
    {
      left: "Source Funds",
      right: `${iso_sender} ${formatCurrency(source)}`,
      boldAll: true,
    },
    {
      icon: divideIcon,
      left: "Rate",
      right: `${iso} 1.00 = ${iso_sender} ${formatCurrency(rate)}`,
      loading: loadingRate,
    },
    { spacer: true },
    {
      left: isConvert ? "Amount Converted" : "Fund allocated",
      right: `${iso} ${formatCurrency(destination)}`,
      boldAll: true,
    },
  ];
  return { array };
};

export const accountCalculatorDefaultProps = (useFormObj) => {
  const { watch, setValue } = useFormObj;
  const watchSource = useDebounce(watch("source"), 400);
  const watchDestination = useDebounce(watch("destination"), 400);
  const [rate, setRate] = useState("");
  const [obj, setObj] = useState({});
  // we need a stopper inside useEffect
  // for clean mutations
  const [stopperBySource, setStopperBySource] = useState(true);

  const defaultSetState = (obj, rate) => {
    setRate(rate);
    setObj({ ...obj, rate });
  };

  useRegisterField(useFormObj, "real_source");
  useRegisterField(useFormObj, "real_destination");

  const defaultSetValue = (name, amount) => {
    setValue(`real_${name}`, amount);
    setValue(name, amount);
  };

  const localFormatter = (obj, isSource, activeFee) => {
    const { final_amount, inverse_rate: rate, fee } = obj || {};
    const getFinalAmount = Number(final_amount) < 0 ? "0" : final_amount;
    defaultSetState(obj, rate);
    if (isSource) return defaultSetValue("destination", getFinalAmount);

    const sourceNumber = Number(final_amount) + Number(activeFee);
    defaultSetValue("source", sourceNumber);
  };

  const formatter = (obj, isSource) => {
    const { destination_amount, source_amount, final_rate, exchange_rate } =
      obj || {};
    const rate = exchange_rate ? 1 / exchange_rate : final_rate;

    defaultSetState(obj, rate);

    if (isSource) return defaultSetValue("destination", destination_amount);
    defaultSetValue("source", source_amount);
  };

  const handleChange = (isSource) => setStopperBySource(isSource);

  return {
    watchSource,
    watchDestination,
    stopperBySource,
    handleChange,
    rate,
    formatter,
    localFormatter,
    obj,
  };
};

const LabelRow = () => (
  <div
    style={{
      padding: 12,
      backgroundColor: "#e5f9fc",
      borderRadius: 8,
    }}
    className="d-flex justify-content-between align-items-center"
  >
    <div className="d-flex align-items-center">
      <img src={WarningIcon} />
      <GothamMedium
        style={{
          color: colors.grey72,
          fontSize: 12,
          marginBottom: 0,
          marginRight: 8,
          marginLeft: 4,
        }}
      >
        Notes
      </GothamMedium>
    </div>
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      <GothamRegular
        style={{
          color: colors.grey72,
          fontSize: 12,
          marginBottom: 0,
          letterSpacing: "0.1px",
        }}
      >
        We will allocate your fund to your global account
      </GothamRegular>
    </div>
  </div>
);

export const AccountCalculator = ({
  title = "",
  array = [],
  useFormObj,
  loadingButton,
  sourceCountry = {},
  destinationCountry = { currency: "SGD", iso: "sg", countryName: "Global" },
  loading,
  onChange,
  onClick,
  disabledButton,
  showBadge = true,
  isDecimal = false,
}) => {
  const { setValue } = useFormObj;
  const { currency, countryName } = destinationCountry || {};
  const { currency: scurrency, countryName: scountryName } =
    sourceCountry || {};

  const isIndo = scurrency == "IDR";

  useEffect(() => {
    if (loading) return;
    if (isIndo) return setValue("source", "20000000");
    setValue("source", "1000");
  }, [loading]);

  return (
    <SmallScreenWrapper title={title}>
      {showBadge && <LabelRow />}
      <div className="d-flex" style={{ marginBottom: 32, marginTop: 24 }}>
        <AccountCalculatorInput
          loading={loading}
          name="source"
          isVertical
          isDecimal={isDecimal}
          onChange={() => onChange(true)}
          useFormObj={useFormObj}
          country={sourceCountry}
          label={`${scountryName} Account`}
          prefix={`-${scurrency}`}
          bluePrefix
        />
        <img src={arrowIcon} style={{ marginRight: 21, marginLeft: 21 }} />
        <AccountCalculatorInput
          loading={loading}
          name="destination"
          isVertical
          onChange={() => onChange()}
          useFormObj={useFormObj}
          country={destinationCountry}
          label={`${countryName} Account`}
          prefix={`+${currency}`}
          bluePrefix
        />
      </div>
      <LeftRightList array={array} />
      <CustomButton
        style={{ width: "100%", marginTop: 32 }}
        isLoading={loadingButton}
        onClick={onClick}
        disabled={disabledButton}
      >
        Continue
      </CustomButton>
    </SmallScreenWrapper>
  );
};
