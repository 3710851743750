import React from "react";
import { colors } from "../assets/colors";
import { BackNavigation } from "../pageComponents/accountDashboardComponents";
import { CloseHeader } from "./CardModal";
import { TypeFezNeueMedium } from "./Text";
import { windowDimension } from "./tools";

export const SmallScreenWrapper = ({
  children,
  title = "",
  onClick,
  woBack,
  styleTitle,
  style,
  containerStyle,
  isModal = false,
  toggle,
}) => {
  const { isTabOrPhone } = windowDimension();

  return (
    <div
      className={`${
        isTabOrPhone ? "mx-3 mt-4" : "mx-5"
      } d-flex justify-content-center`}
      style={containerStyle}
    >
      <div style={{ width: isTabOrPhone ? "100%" : 480, ...style }}>
        {isModal && (
          <div style={{ marginRight: 6, marginTop: 24, marginBottom: 24 }}>
            <CloseHeader
              toggle={toggle}
              color={colors.pink}
              isMedium
              isJustClose
            />
          </div>
        )}
        {!woBack && !isTabOrPhone && (
          <BackNavigation style={{ marginBottom: 8 }} onClick={onClick} />
        )}
        {Boolean(title && !isTabOrPhone) && (
          <TypeFezNeueMedium
            className="font24"
            style={{
              color: colors.blue10,
              ...styleTitle,
            }}
          >
            {title}
          </TypeFezNeueMedium>
        )}
        {children}
      </div>
    </div>
  );
};
