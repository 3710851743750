import _, { get, lowerCase } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { colors, jackColors } from "../assets/colors";
import { useAccountCalculator } from "../components/AccountInputs";
import { GetFlag, useRegisterField } from "../components/inputs";
import { useModalHook } from "../components/Modals";
import { GothamMedium, GothamRegular } from "../components/Text";
import {
  flagIsoCode,
  formatCurrency,
  getIso3,
  newCalculatorItemsPreparer,
  useDebounce,
  windowDimension,
} from "../components/tools";
import { fetch } from "../tools/api";
import { eventsTracker } from "../universalFunctions/events";
import { accountCalculatorDefaultProps } from "./AccountCalculator";
import { ModalSelectCountry } from "../pageComponents/crossBorder/selection/modal";
import { noCase } from "change-case";
import Skeleton from "react-loading-skeleton";
import { JackIcons } from "../assets/jackIcons/parent";
import { useTranslation } from "react-i18next";

export const getCurrency = (data) => {
  const { value = "" } = data || {};
  const isLocal = value.includes("_");
  if (isLocal) return value.split("_")[0];
  return value;
};

export const toIso3 = (obj) => {
  const { country_iso = "" } = obj || {};

  const result = getIso3(country_iso);
  return result;
};

const calculatorProps = (useFormObj) => {
  const { watch } = useFormObj;

  const source_country = watch("source_country");
  const destination_country = watch("destination_country");

  const source_currency = getCurrency(source_country);
  const destination_currency = getCurrency(destination_country);

  const source_country_iso_3 = toIso3(source_country);
  const destination_country_iso_3 = toIso3(destination_country);

  const source_country_value = source_country?.value;

  return {
    source_currency,
    destination_currency,
    source_country_iso_3,
    destination_country_iso_3,
    source_country_value,
  };
};

export const useCalculatorChangeSourceCountry = ({
  isLocal,
  handleRefetch = () => {},
  useFormObj,
}) => {
  const { destination_currency, source_country_value } =
    calculatorProps(useFormObj);

  // let me explain.
  // 1. isFirstRender => used to avoid unnecessary hook calls
  // 2. isLocalPrevious => used to determine is it time to insert new destination values?
  // 3. sourceStopper => we need to stop refetch from source when `changedToLocal || changedToGlobal` active.
  //                     If not, it will be calling unstable URL (because our URL based by `isLocal` props)

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isLocalPrevious, setIsLocalPrevious] = useState(false);
  const [sourceStopper, setSourceStopper] = useState(false);

  const debouncedSourceValue = useDebounce(
    sourceStopper ? "" : source_country_value,
    100
  );
  const { setValue } = useFormObj;

  // sets NEW defaultValue here!
  useEffect(() => {
    setIsLocalPrevious(isLocal);
    const changedToLocal = isLocal && !isLocalPrevious;
    const changedToGlobal = !isLocal && isLocalPrevious;
    const stopped = changedToLocal || changedToGlobal;

    if (stopped) {
      setSourceStopper(true);

      const value = changedToGlobal
        ? destination_country
        : local_destination_country;

      return setValue("destination_country", value);
    }

    setSourceStopper(false);
  }, [isLocal, source_country_value]);
  // sets NEW defaultValue here!

  const defaultRefetch = () => {
    if (isFirstRender) return setIsFirstRender(false);
    handleRefetch();
  };

  useEffect(() => {
    if (!debouncedSourceValue) return;
    defaultRefetch();
  }, [debouncedSourceValue]);

  useEffect(() => {
    defaultRefetch();
  }, [destination_currency]);
};

export const useCalculator = (useFormObj, isLocal, activeFee, labelCountry) => {
  const {
    destination_country_iso_3,
    destination_currency,
    source_country_iso_3 = "IDN",
    source_currency = "IDR",
  } = calculatorProps(useFormObj);

  const {
    localFormatter,
    formatter,
    handleChange,
    stopperBySource,
    watchDestination,
    watchSource,
    obj,
  } = accountCalculatorDefaultProps(useFormObj);

  const getUrl = (amount, type) => {
    if (isLocal) {
      const isSource = type == "source";
      const amount_type = isSource ? "normal" : "inverse";
      const getAmount = isSource
        ? Number(amount) - Number(activeFee || 0)
        : amount;

      return `/rate_calculator?source_country=${source_country_iso_3}&source_currency=${
        source_currency || "IDR"
      }&destination_country=${destination_country_iso_3}&destination_currency=${destination_currency}&amount=${getAmount}&amount_type=${amount_type}`;
    }
    return `/transfers/exchange_rate_lock_and_hold?source_currency=${source_currency}&destination_currency=${destination_currency}&amount=${amount}&type_of_amount=${type}`;
  };

  const sourceUrl = getUrl(watchSource, "source");
  const destinationUrl = getUrl(watchDestination, "destination");
  const isWatchDestinationUndefined =
    watchDestination === undefined || watchDestination === NaN;
  const isWatchSourceUndefined =
    watchSource === undefined || watchSource === NaN;
  const isUndefined = !isWatchDestinationUndefined || !isWatchSourceUndefined;

  const getFormatter = (data, isSource) => {
    if (isLocal) return localFormatter(data, isSource, activeFee);
    return formatter(data?.data, isSource);
  };

  //SOURCE API
  const { refetch: refetchSource, loading: loadingSource } = fetch({
    url: sourceUrl,
    woInit: true,
    formatter: (data) => getFormatter(data, true),
  });
  //SOURCE API

  //DESTINATION API
  const { refetch: refetchDestination, loading: loadingDestination } = fetch({
    url: destinationUrl,
    woInit: true,
    formatter: (data) => getFormatter(data),
  });
  //DESTINATION API

  useRegisterField(useFormObj, "real_source");
  useRegisterField(useFormObj, "real_destination");

  const { setValue } = useFormObj;

  useEffect(() => {
    if (!stopperBySource) return;
    if (!watchSource) return;
    setValue("real_source", watchSource);
    if (isUndefined) {
      refetchSource();
    }
  }, [watchSource, activeFee, labelCountry]);

  useEffect(() => {
    if (stopperBySource) return;
    if (!watchDestination) return;
    setValue("real_destination", watchDestination);
    if (isUndefined) {
      refetchDestination();
    }
  }, [watchDestination, activeFee]);
  // API CALLS BASED BY CHANGE AMOUNT

  // API CALLS BASED BY CHANGE COUNTRY
  const [refetchOnceAgain, setRefetchOnceAgain] = useState(false);
  useCalculatorChangeSourceCountry({
    isLocal,
    handleRefetch: async () => {
      // we need to call handleChange to FREEZE `watchDestination` useEffect
      handleChange(true);
      await refetchSource();
      setRefetchOnceAgain(true);
    },
    useFormObj,
  });

  useEffect(() => {
    if (!refetchOnceAgain) return;
    setRefetchOnceAgain(false);
    handleChange(true);
    if (isUndefined) {
      refetchSource();
    }
  }, [refetchOnceAgain]);
  // API CALLS BASED BY CHANGE COUNTRY

  const loadingCalculator = loadingSource || loadingDestination;

  return { loadingCalculator, handleChange, obj };
};

const sourceLocalFormatter = (data) => {
  const obj = data?.data?.partner?.disbursement_balance[0] || {};
  return obj;
};

const sourceGlobalFormatter = (data, localData) => {
  const array = data?.data || [];

  const combinedWithLocal = [localData, ...array];

  const result = combinedWithLocal.map((item) => {
    const { curSymbol, balance, isoCode, currency: currencyLocal } = item;

    const realCurrency = currencyLocal || curSymbol;
    const isLocal = Boolean(currencyLocal);

    const label = isLocal
      ? `Local - ${realCurrency} Account`
      : `Global - ${realCurrency} Account`;
    const currency_label = realCurrency;
    const currency = `${realCurrency} ${formatCurrency(balance)}`;
    const country_iso = isLocal ? "id" : flagIsoCode(isoCode);
    const value = isLocal ? `${realCurrency}_local` : realCurrency;

    const result = {
      ...item,
      label,
      currency,
      country_iso,
      value,
      currency_label,
    };

    return result;
  });

  return result;
};

const source_country = {
  available_balance: null,
  balance: 353609990,
  country_iso: "id",
  created_at: "2020-07-30T13:23:39.900+07:00",
  currency: "IDR",
  currency_label: "IDR",
  id: 7,
  is_active: true,
  is_unlimited: true,
  label: "Local - IDR Account",
  partner_id: 6,
  updated_at: "2022-01-07T13:41:14.494+07:00",
  user_id: 7,
  value: "IDR_local",
};

const local_destination_country = {
  country_id: 184,
  country_iso: "sg",
  currency: "SGD",
  currency_label: "SGD",
  label: "Singapore/ SGD",
  value: "SGD",
  disbursement_type: ["B2B", "B2C"],
};

const destination_country = {
  label: "USD / United States",
  currency: "USD",
  country_iso: "us",
  currency_label: "USD",
  value: "USD",
};

export const useCalculatorOptions = (isLocal) => {
  // GET SOURCE
  const sourceData = [source_country];
  const sourceLoading = false;

  // GET DESTINATION
  const { data: destinationLocalData, loading: destinationLocalLoading } =
    fetch({
      url: "/rates/available",
      formatter: (data) => {
        const targetCountries = get(data, "destination_countries", []).filter(
          ({ currency }) => currency !== "IDR"
        );
        const oldResult = newCalculatorItemsPreparer(targetCountries);
        const newResult = oldResult.map((item) => {
          const { country_iso, currency } = item;
          const value = `${currency}_${country_iso}`;
          return {
            ...item,
            value,
            country_iso: lowerCase(country_iso || ""),
            currency_label: value,
          };
        });
        return newResult;
      },
    });

  const destinationGlobalData = [];
  const destinationGlobalLoading = false;

  const destinationData = isLocal
    ? destinationLocalData
    : destinationGlobalData;

  const destinationLoading = isLocal
    ? destinationLocalLoading
    : destinationGlobalLoading;
  // GET DESTINATION

  // COMBINER
  const loading = destinationLoading || sourceLoading;
  // COMBINER

  // DEFAULT VALUES

  const defaultValues = {
    source_country,
    destination_country,
  };
  // DEFAULT VALUES

  return { defaultValues, sourceData, destinationData, loading };
};

export const MaskingLabelCurrency = ({ currency, noSwift = false }) => {
  if (currency === "SGD-SWIFT") {
    if (noSwift) return "SGD";
    return "SGD (Swift)";
  }
  if (currency === "USD-SWIFT") {
    if (noSwift) return "USD";
    return "USD (Swift)";
  }
  return currency;
};

export const CalculatorInput = ({
  useFormObj,
  options = [],
  name,
  onChange,
  defaultAmount = 0,
  isDecimal,
  loading,
  data = {},
  errorAmount = "",
  dataFormatted,
  setDataToInput,
  loadingInput,
  isFirstRender,
}) => {
  const { t } = useTranslation('international/create');
  const [search, setSearch] = useState("");
  const { watch } = useFormObj;
  const watchDestCountry = watch("destination_country");
  const isSource = name === "source";
  const { currency: currDestination, country_iso: isoDestination } =
    watchDestCountry || {};
  const { currency: currSource, country_iso: isoSource } = options[0] || {};
  const currency = isSource ? currSource : currDestination;
  const iso = isSource ? isoSource : isoDestination;

  // options Shape
  // [
  //   {
  //    label: "Global - AUD Account",
  //    currency: "AUD 1,500.50",
  //    country_iso: "id",
  //    value: "AUD",
  //   },
  // ],

  const { isOpen, toggle, close } = useModalHook();

  const getCountries = options.filter(({ country_iso }) => country_iso === iso);

  const handleClick = (val) => {
    // setSelectedData(val);
    setDataToInput(val);
    close();
  };

  const { handleChange, text } = useAccountCalculator({
    useFormObj,
    name,
    onChange,
    defaultAmount,
    isDecimal,
  });

  const handleChangeCurr = (val) => {
    eventsTracker("change_destination_currency", val);
    setValue(selectionName, val);
  };

  const handleModal = () => {
    if (!isSource) toggle();
  };

  const sortedData = _.sortBy(dataFormatted, "country_name");
  // const newData = deepRemoveDuplicates(sortedData, "country_name");
  const filteredArray = sortedData?.filter(
    ({ country_name, country_iso_3, currency }) =>
      noCase(country_name).includes(noCase(search)) ||
      noCase(country_iso_3).includes(noCase(search)) ||
      noCase(currency).includes(noCase(search))
  );

  const isEmptySearch = !Boolean(filteredArray.length);

  const selectionName = `${name}_country`;

  const { setValue, currValue: currValueSelection } = useRegisterField(
    useFormObj,
    selectionName
  );

  useEffect(() => {
    if (Boolean(data)) {
      setValue(selectionName, data);
    }
    if (isSource) {
      setValue(selectionName, options[0]);
    }
  }, [data.currency_label]);

  const labelCurrency = MaskingLabelCurrency({ currency });

  return (
    <div className="borderE6" style={{ padding: "8px 12px" }}>
      <div
        className="d-flex justify-content-between"
        style={{ position: "relative" }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            width: 140,
            position: "relative",
            cursor: !isSource && "pointer",
            whiteSpace: "nowrap",
          }}
          onClick={handleModal}
        >
          {loading ? (
            <Skeleton width={70} height={30} />
          ) : (
            <>
              <GetFlag iso={iso} style={{ marginLeft: 0, marginRight: 4 }} />
              <div
                className="d-flex align-items-center"
                style={{
                  padding: "2px 6px",
                  borderRadius: 100,
                  backgroundColor: isOpen && jackColors.neutral400,
                }}
              >
                <GothamMedium style={{ color: jackColors.neutral900 }}>
                  {labelCurrency}
                </GothamMedium>
                {!isSource && (
                  <JackIcons
                    name="chevron-down"
                    fill={jackColors.neutral900}
                    style={{ width: 20, height: 20 }}
                  />
                )}
              </div>
            </>
          )}
        </div>
        {isOpen && (
          <ModalSelectCountry
            close={close}
            search={search}
            isOpen={isOpen}
            array={filteredArray}
            isEmptySearch={isEmptySearch}
            setSearch={setSearch}
            handleClick={handleClick}
          />
        )}
        <div className="text-right">
          <GothamRegular
            className="font12"
            style={{
              marginBottom: 4,
              textAlign: "right",
              color: jackColors.neutral700,
            }}
          >
            {name == "source" ? t("You pay") : t("Recipient gets")}
          </GothamRegular>
          {loadingInput ? (
            <Skeleton width={70} height={22} />
          ) : (
            <input
              className="w-100 font20"
              value={text}
              onChange={handleChange}
              style={{
                backgroundColor: "transparent",
                borderWidth: 0,
                lineHeight: "20px",
                outline: "none",
                textAlign: "right",
                fontFamily: "GothamMedium",
                color: jackColors.neutral900,
              }}
            />
          )}
          {!isFirstRender && Boolean(errorAmount) && (
            <div className="text-right">
              <GothamRegular
                style={{
                  fontSize: 10,
                  color: colors.redE9,
                  whiteSpace: "nowrap",
                }}
              >
                {errorAmount}
              </GothamRegular>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
